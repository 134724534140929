<template>
  <div id="token-data" class="uk-flex">
    <div class="uk-flex uk-flex-column uk-text-center">
      <span class="label">last price</span>
      <span v-if="loading" style="height: 100%; margin-right: -10px">
        <vue-loaders-ball-pulse
          color="currentColor"
          scale=".5"
          style="height: 100%; transform: scale(0.5)"
        />
      </span>
      <span v-else
        >${{
          fromTokenPrice.toLocaleString(
            undefined,
            digits(isFxToken(fromToken.symbol) ? 4 : 2)
          )
        }}</span
      >
    </div>
    <div class="divider" />
    <div class="uk-flex uk-flex-column uk-text-center">
      <span class="label">24h change</span>
      <span v-if="loading" style="height: 100%; margin-right: -10px">
        <vue-loaders-ball-pulse
          color="currentColor"
          scale=".5"
          style="height: 100%; transform: scale(0.5)"
        />
      </span>
      <span v-else class="value"
        >${{
          fromToken24hChange.toLocaleString(
            undefined,
            digits(isFxToken(fromToken.symbol) ? 4 : 2)
          )
        }}
        ({{
          fromToken24hChangePct.toLocaleString(undefined, digits(2))
        }}%)</span
      >
    </div>
    <div class="divider" />
    <div class="uk-flex uk-flex-column uk-text-center">
      <span class="label">total volume</span>
      <span v-if="loading" style="height: 100%; margin-right: -10px">
        <vue-loaders-ball-pulse
          color="currentColor"
          scale=".5"
          style="height: 100%; transform: scale(0.5)"
        />
      </span>
      <span v-else class="value"
        >${{ fromTokenTotalVolume.toLocaleString(undefined, digits(2)) }}</span
      >
    </div>
  </div>
</template>

<script>
import { getTokenPriceData, getNativeTokenPriceData } from "@/utils/coingecko";
import { format } from "date-fns";
import Token from "@/types/Token";
import { store } from "@/store";
import { isNativeToken } from "@/utils/utils";
import { NETWORK_NAMES } from "@/utils/constants/networks";
import { getPriceData } from "@/utils/prices";

export default {
  name: "TokenData",
  props: {
    id: { type: String, default: "chart" },
    disabled: { type: Boolean },
    pricesNetwork: { type: String, default: "" },
    fromToken: { type: Object, default: () => {} },
    toToken: { type: Object, default: () => {} },
  },

  data() {
    return {
      days: 1,
      digitCount(num) {
        if (num === 0) return 1;
        return Math.floor(Math.log10(Math.abs(num))) + 1;
      },
      dataRefreshIntervalId: null,
      fromTokenChange: false,
      toTokenChange: false,
      prevHoverPrice: 0,
      hoverPrice: 0,
      tokenList: [],
      NETWORK_NAMES,
      refreshChart: false,
      fxTokenDataError: false,
      fromTokenMarketCap: 0,
      fromTokenTotalVolume: 0,
      fromTokenPrice: 0,
      fromToken24hChange: 0,
      fromToken24hChangePct: 0,
      fromToken24hVolume: 0,
      loading: true,
    };
  },

  async mounted() {
    await this.buildHeader();
  },

  beforeDestroy() {
    if (!this.dataRefreshIntervalId) return;
    clearInterval(this.dataRefreshIntervalId);
  },

  computed: {
    network() {
      return store.state.network;
    },
    account() {
      return store.state.account;
    },
    pricesNetworkName() {
      return this.pricesNetwork ? this.pricesNetwork.split(" ")[0] : "";
    },
    fromToTokens() {
      return this.fromToken && this.toToken
        ? `${this.fromToken.symbol}/${this.toToken.symbol}`
        : null;
    },
    priceMovement() {
      return this.chartData.length === 0
        ? 0
        : this.hoverPrice - this.chartData[0].price;
    },
  },

  watch: {
    disabled() {
      if (this.disabled) {
        const svg = d3.select("#" + this.id + "Svg");
        svg.selectAll("*").remove();
      }
    },

    async network(val, oldVal) {
      if (this.fromTokenChange || this.toTokenChange) return;
      await this.buildHeader();
    },

    async fromToken(val, oldVal) {
      if (!val || this.toTokenChange) return;

      if (val.address === oldVal.address) return;

      this.fromTokenChange = true;
      this.fxTokenDataError = false;

      await this.buildHeader();
    },

    async toToken(val, oldVal) {
      if (!val || this.fromTokenChange) return;

      if (
        val.address === oldVal.address &&
        val.pricesAddress === oldVal.pricesAddress
      )
        return;

      this.toTokenChange = true;
      this.fxTokenDataError = false;

      await this.buildHeader();
    },
    deep: true,
  },

  methods: {
    isFxToken(token) {
      return token.startsWith("fx") && token.length === 5;
    },

    async buildHeader() {
      if (!this.fromToken || !this.toToken) return;

      if (this.dataRefreshIntervalId) clearInterval();

      this.loading = true;
      await this.getData();
      this.loading = false;

      if (!this.dataRefreshIntervalId)
        this.dataRefreshIntervalId = setInterval(
          () => this.dataRefreshIteration(),
          60000
        );
    },

    async getData() {
      if (!this.fromToken.address || !this.toToken.address) return;

      if (!this.refreshChart) {
        this.chartData = [];
      }

      this.fxTokenDataError = false;

      const priceDataPromise = (token) =>
        isNativeToken(token.symbol)
          ? getNativeTokenPriceData(token.symbol, "USD", this.days)
          : this.isFxToken(token.symbol)
          ? getPriceData(token.symbol.slice(-3), "USD", this.days)
          : getTokenPriceData(
              this.pricesNetworkName,
              token.address,
              "USD",
              this.days
            );

      try {
        let [fromTokenPriceData, toTokenPriceData] = await Promise.all([
          priceDataPromise(this.fromToken),
          //priceDataPromise(this.toToken),
        ]);

        if (this.isFxToken(this.fromToken.symbol)) {
          if (
            fromTokenPriceData.status === "error" ||
            !fromTokenPriceData.values
          ) {
            this.fxTokenDataError = true;
            if (this.dataRefreshIntervalId) clearInterval();
          } else {
            this.fxTokenDataError = false;

            fromTokenPriceData.prices = fromTokenPriceData.values
              .sort((a, b) => (a.datetime > b.datetime ? 1 : -1))
              .map((priceData) => {
                return [
                  Number(format(new Date(priceData.datetime), "T")),
                  (Number(priceData.close) + Number(priceData.open)) / 2,
                ];
              });
          }
        }

        if (this.isFxToken(this.toToken.symbol)) {
          if (toTokenPriceData.status === "error" || !toTokenPriceData.values) {
            this.fxTokenDataError = true;
            if (this.dataRefreshIntervalId) clearInterval();
          } else {
            this.fxTokenDataError = false;

            toTokenPriceData.prices = toTokenPriceData.values
              .sort((a, b) => (a.datetime > b.datetime ? 1 : -1))
              .map((priceData) => {
                return [
                  Number(format(new Date(priceData.datetime), "T")),
                  (Number(priceData.close) + Number(priceData.open)) / 2,
                ];
              });
          }
        }

        if (this.fxTokenDataError) return;

        this.fromTokenPrice =
          fromTokenPriceData.prices[fromTokenPriceData.prices.length - 1][1];
        this.fromToken24hChange =
          this.fromTokenPrice - fromTokenPriceData.prices[0][1];
        this.fromToken24hChangePct =
          (this.fromToken24hChange / fromTokenPriceData.prices[0][1]) * 100;

        this.fromTokenMarketCap = fromTokenPriceData.market_caps
          ? fromTokenPriceData.market_caps[
              fromTokenPriceData.market_caps.length - 1
            ][1]
          : "n/a";

        this.fromTokenTotalVolume = fromTokenPriceData.total_volumes
          ? fromTokenPriceData.total_volumes[
              fromTokenPriceData.total_volumes.length - 1
            ][1]
          : "n/a";
      } catch (e) {
        console.error("Get prices error:", e);
        return;
      }
    },

    dataRefreshIteration() {
      if (!this.dataRefreshIntervalId) return;
      this.refreshChart = true;
      this.buildHeader();
      this.refreshChart = false;
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },
  },
};
</script>

<style lang="scss">
@use "src/assets/styles/handle.fi" as handle;
#token-data {
  grid-area: trade-header;

  .divider {
    height: 20px;
    border-left: 1px solid handle.$green;
    margin: 15px;
  }
  .label {
    font-size: 12px;
  }
}
svg text {
  font-family: handle.$font;
}

svg.homestead-color {
  .eth-fill-1 {
    fill: handle.$homestead-color !important;
  }
}

svg.ropsten-color {
  .eth-fill-1 {
    fill: handle.$ropsten-color !important;
  }
}

svg.kovan-color {
  .eth-fill-1 {
    fill: handle.$kovan-color !important;
  }
}

svg.rinkeby-color {
  .eth-fill-1 {
    fill: handle.$rinkeby-color !important;
  }
}

svg.goerli-color {
  .eth-fill-1 {
    fill: handle.$goerli-color !important;
  }
}

.area {
  fill: url(#area-gradient);
}
</style>
