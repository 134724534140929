<template>
  <div id="trading-view" class="uk-flex uk-width-1-1 uk-height-1-1">
    <VueTradingView
      :key="chartSymbol"
      v-if="chartSymbol && options"
      :options="options"
    />
  </div>
</template>

<script>
import VueTradingView from "vue-trading-view";
import Token from "@/types/Token";

export default {
  name: "TradingView",
  props: {
    fromToken: { type: String, default: Token.ETH },
    toToken: { type: String, default: Token.ETH },
  },
  components: {
    VueTradingView,
  },
  data() {
    return {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  },
  mounted() {},
  computed: {
    chartSymbol() {
      return this.fromToken + this.toToken;
    },
    options() {
      return {
        symbol: this.chartSymbol,
        hide_legend: false,
        theme: "dark",
        interval: "5",
        timezone: this.timeZone,
        save_image: false,
        show_popup_button: false,
        toolbar_bg: "transparent",
        style: "1",
      };
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/handle.fi.scss" as handle;

#vue-trading-view {
  width: 100%;
  height: 100%;
  * {
    width: 100% !important;
    height: 100% !important;
  }
}

.uk-badge {
  background-color: handle.$green;
  color: handle.$background;
  &:hover {
    color: handle.$background;
    font-weight: 1000;
  }
}
</style>
