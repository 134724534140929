<template>
  <div class="uk-container uk-container-xlarge uk-width-1-1">
    <vue-headful
      description="trade token pairs"
      image="src/assets/logo.png"
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | trade`"
    />
    <TradeComponent />
  </div>
</template>

<script>
import TradeComponent from "@/components/TradeComponent";
import { store } from "@/store";

export default {
  name: "Trade",
  components: {
    TradeComponent,
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../assets/styles/handle.fi" as handle;
</style>
